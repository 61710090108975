import React, {useContext, useEffect} from "react"
import styled from "styled-components"
import GlobalContext from "../GlobalContext/GlobalContext"

const CategoryList = styled(({ className, children }) => {
  const { stars, dispatch } = useContext(GlobalContext)

  useEffect(() => {
    // console.log('MAIN USE EFFECT STORAGE: ', window.localStorage.getItem('favourites'))
    if(window.localStorage.getItem('favourites')?.indexOf(',') > 0 ) {
      // console.log("MORE ITEM",window.localStorage.getItem('favourites'))
      dispatch({type: "setFavourites", payload: window.localStorage.getItem('favourites').split(",")})
    }else if (window.localStorage.getItem('favourites') !== null) {
      // console.log("ONE ITEM",window.localStorage.getItem('favourites'))
      dispatch({type: "addFavourite",payload: window.localStorage.getItem('favourites') })
    }
  },[])
  return <div className={className}>{children}</div>
})`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1600px;
  margin: -100px auto 50px auto;
  padding: 0 20px;
  > div {
    @media screen and (min-width: 768px) {
      width: 49%;
    }
    @media screen and (min-width: 1040px) {
      width: 32%;
      margin-bottom: 50px;
    }
  }
`

export default CategoryList
