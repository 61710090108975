import React, { useEffect, useState, useContext } from "react"
import { useLocation } from "@reach/router";
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { FaStar, FaRegStar, FaTwitter, FaFacebook,FaLinkedin, FaFilePdf } from "react-icons/fa"
import { HiShare } from "react-icons/hi"
import { TwitterShareButton, FacebookShareButton, LinkedinShareButton  } from "react-share"
import GlobalContext from "../GlobalContext/GlobalContext"
const localization = {
  buttonText: {
    en: "Learn more",
    sr: "Saznaj vise",
  },
}

const CategoryItem = styled(
  ({ className, image,categoryTitle, title, price, description, lang, slug, vat, broschure, uid }) => {
    const formatedPrice = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
      maximumFractionDigits: 2,
    }).format(parseInt(price))
    let location = useLocation()
    const { stars, dispatch } = useContext(GlobalContext)

    let isSetStart = false;
    let lf ;
    
    const [starFilled, setStarFilled] = useState(isSetStart)
    const [hovered, setHovered] = useState(false)

    const setStar = (uid,e) => {
     setStarFilled(prev => !prev)
    }
    useEffect(() => {

        if(window.localStorage.getItem('favourites')) {
            if(window.localStorage.getItem('favourites').indexOf(',') > 0 ) {
                lf = window.localStorage.getItem('favourites').split(',')
                if(lf.find(l => {
                    // console.log("First Load star:", l)
                    return l === uid
                })) {
                    // isSetStart = true
                    setStarFilled(true)
                }
                // dispatch({type: "setFavourites", payload: lf})
            }else {
                if(window.localStorage.getItem('favourites') === uid) {
                    // isSetStart = true
                    setStarFilled(true)
                }
                // dispatch({type: "addFavourite",payload: window.localStorage.getItem('favourites') })
            }
        }
    },[])

    useEffect(() => {
      if(starFilled && stars.find(l => l === uid) === undefined) {
        dispatch({type: "addFavourite", payload: uid})
      }else if(stars.length > 0 && !starFilled ) {
          // console.log("stars.length > 0 && !starFilled: ", stars )
        dispatch({type: "removeFavourite", payload: uid})
        }
    },[starFilled,uid])
    return (
      <div className={className}>
        <div className="cat-image">
          <div className="border" />
          <GatsbyImage image={getImage(image)} alt={title} />
        </div>
        <div className="short-info">
          <h4>{title}</h4>
          <span>
            {categoryTitle !== "Yachts for sale" ?
                (!vat && categoryTitle !== "Yachts for sale"
                    ? `${formatedPrice}/week + VAT + 30% APA`
                    : `${formatedPrice}/week + ${vat}`):
                `Asking price ${formatedPrice}`
            }
            {/*{!vat && categoryTitle !== "Yachts for sale"*/}
            {/*  ? `${formatedPrice}/week + VAT + 30% APA`*/}
            {/*  : `${formatedPrice}/week + ${vat}`}*/}
          </span>
          <span className="separator">-</span>
          <span>{description}</span>
          <span className="separator">-</span>
            {/*{broschure && <a target="_blank" className="brochure" href={broschure}>Brochure <FaFilePdf size={20} color="#776d61" /></a> }*/}
        </div>
        <Link className="read-more-link" to={slug}>
          {localization.buttonText[lang]}
        </Link>
          <div className="share-buttons">
              <div role="button" tabIndex="0" className="stars" onClick={setStar.bind(this,uid)}>
                  { starFilled && <FaStar />}
                  { !starFilled && <FaRegStar />}
              </div>
              <div className="social">
                  <div style={{opacity: hovered ? 1 : 0, display: hovered ? "flex" : "none"}} className="social-icons">
                      <TwitterShareButton url={`${location.protocol}//${location.host}${slug}`} size={24}>
                          <FaTwitter color="#ab9a87" size={24} />
                      </TwitterShareButton>
                      <FacebookShareButton url={`${location.protocol}//${location.host}${slug}`} size={24} >
                          <FaFacebook color="#ab9a87" size={24} />
                      </FacebookShareButton>
                      <LinkedinShareButton url={`${location.protocol}//${location.host}${slug}`}>
                          <FaLinkedin color="#ab9a87" size={24} />
                      </LinkedinShareButton>
                  </div>
                  <div style={{display:"flex", justifyContent: "center", alignItems:"center"}} onClick={e => setHovered(prev => !prev)} >
                      <HiShare className="share-button" />
                  </div>

              </div>
          </div>
      </div>
    )
  }
)`
  width: 100%;
  position: relative;
  border-radius: 2px;
  background-color: var(--light-grey);
  border-left: 1px solid #00578a;
  border-right: 1px solid #00578a;
  border-bottom: 1px solid #00578a;
  margin-bottom: 30px;
  padding-bottom: 50px;
  //@media screen and (min-width: 768px) {
  //  width: 49%;
  //}
  //@media screen and (min-width: 1040px) {
  //  width: 32%;
  //  margin-bottom: 50px;
  //}
  .brochure {
    text-decoration: none;
    font-size:13px;
    color:#776d61;
    font-weight: bold;
    text-transform: uppercase;
  }
  .cat-image {
    position: relative;
    overflow: hidden;
    .border {
      position: absolute;
      top: 0;
      left: -0;
      right: 0;
      width: 100%;
      height: 8px;
      background-color: var(--gold);
      z-index: 5;
    }
  }
  .share-buttons {
    //position:relative;
    position:absolute;
    //@media screen and (min-width: 600px) {
    //  position: absolute;
    //}
    display:flex;
    justify-content: center;
    bottom:10px;
    right:10px;
    > div {
      display:flex;
      justify-content: center;
      align-items: center;
      margin-right:15px;
    }
  }
  .share-button {
    color: var(--grey);
    &:hover {
      cursor: pointer;
    }
  }
  .social {
    position:relative;
  }
  .social-icons {
    position:absolute;
    background-color: #E3E4E6;;
    justify-content: space-between;
    padding:30px 15px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-left-radius: 50%;
    //border-radius: 50%;
    //padding:10px 15px;
    //border: 1px solid var(--light-grey);
    bottom:135%;
    right:30%;
    button {
      display:flex;
      justify-content: center;
      align-items: center;
    }
    svg {
      margin-right: 10px;
    }
    //width: 200px;
    //height:30px;
  }
  .short-info {
    padding: 30px;
    font-size: 16px;
    line-height: 16px;
    color: #8c8c8c;
    h4 {
      margin-top: 0;
      margin-bottom: 5px;
      font-size: 25px;
      line-height: 24px;
      color: #776d61;
      font-weight: 400;
      font-family: "Tenor Sans", serif;
    }
    span {
      display: block;
    }
  }
  .read-more-link {
    position: absolute;
    top: calc(100% - 30px);
    left: 15%;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 14px;
    color: #776d61;
    padding: 15px 35px;
    border: 2px solid var(--gold);
    border-radius: 3px;
    z-index: 5;
    background: white;
  }
`

export default CategoryItem
