import React, { useContext, useEffect } from "react"
import Layout from "../components/layout"
import {graphql, Link} from "gatsby"
import SectionSeparator from "../components/SectionHorizontalSeparator"
import SectionBannerImage from "../components/SectionBanner"
import Anim from "../components/Anim"
import { StaticImage, getImage } from "gatsby-plugin-image"
import SectionBox from "../components/SectionBox"
import CategoryList from "../components/CategoryList/CategoryLIst"
import CategoryItem from "../components/CategoryItem/CategoryItem"
import Button from "../components/Button"
import GlobalContext from "../components/GlobalContext/GlobalContext"
import DescriptionPart from "../components/DescriptionPart"
import HelpSection from "../components/HelpSection"

// export const DescriptionPart = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   padding-left: 20px;
//   padding-right: 20px;
//   padding-bottom: ${({ paddingBottom }) =>
//     paddingBottom ? paddingBottom : "50px"};
//   padding-top: 50px;
//   background: ${({ bgColor }) => (bgColor ? bgColor : "var(--blue)")};
//   margin-top: -60px;
//   .title {
//     font-size: calc(24px + (60 - 24) * ((100vw - 300px) / (1600 - 300)));
//     text-transform: none;
//     font-weight: 300;
//   }
//   h1,
//   h2,
//   h3,
//   h4,
//   h5,
//   h6 {
//     font-size: calc(17px + (24 - 17) * ((100vw - 300px) / (1600 - 300)));
//     letter-spacing: 5px;
//     line-height: 29px;
//     text-transform: uppercase;
//     color: #ffffff;
//     font-weight: bold;
//     text-align: center;
//     margin: 0 auto 25px auto;
//     max-width: 800px;
//   }
//   p,
//   div {
//     max-width: 800px;
//     margin: 10px auto;
//     font-size: calc(17px + (21 - 17) * ((100vw - 300px) / (1600 - 300)));
//     line-height: 29px;
//     color: #ffffff;
//     font-weight: 200;
//     text-align: center;
//   }
// `

// export const HelpSection = styled(({ className, children }) => {
//   return <div className={className}>{children}</div>
// })`
//   display: flex;
//   flex-direction: column;
//   width: 80%;
//   margin: auto;
//   max-width: 800px;
//   justify-content: center;
//   align-items: center;
//   padding: 70px 0;
//   h4 {
//     text-transform: uppercase;
//     text-align: center;
//     color: var(--blue);
//   }
//   p {
//     font-size: 20px;
//     line-height: 29px;
//     color: #00578a;
//     font-weight: 300;
//     text-align: center;
//   }
// `
const localText = {
  help: {
    sr: "",
    en: "Please get in touch to discuss your needs and find right vessel for you."
  }
}
const OfferList = ({ pageContext, location, data }) => {
  const { defLang, otherLang, lang: pLang, slug, categoryData } = pageContext

  // console.log("DATA FETCHED: ", data)

  const { lang, stars, dispatch } = useContext(GlobalContext)
  // console.log("Laaaaang: ", lang)
  useEffect(() => {
    // console.log("STARS IN CATEGORY LIST: ", stars)
    stars.length > 0 ? window.localStorage.setItem('favourites',stars ) :  window.localStorage.removeItem('favourites')
  },[stars])

  useEffect(() => {
    // window.localStorage.setItem('favourites',stars )
    dispatch({ type: "changeLanguage", payload: pLang })
  }, [pLang,stars,dispatch])

  const metaInfo = categoryData.locales.find((l) => l.iso === lang)

  const { title:categoryTitle, content } = categoryData.locales.find((l) => l.iso === lang)

  // console.log("OFFER DATA: ", data)

  // console.log("categoryDATA: ", categoryData)

  // const img = getImage(categoryData.imageFile)

  let sortedOffers = []
  const offersInStars = data?.allOffer?.nodes.filter(o => {
    const finded = stars.find(s => s === o.uid)
    return !!finded
  })
  const noOffersInStars = data?.allOffer?.nodes.filter(o => {
    let wehave = true;
    stars.forEach(s => {
      if(s === o.uid) {
        wehave = false
      }
    })
    return wehave
  })

  if (offersInStars.length > 0) {
    // console.log("NO OFFERS IN STARS:", noOffersInStars)
    sortedOffers = [...offersInStars, ...noOffersInStars]
  }else {
    sortedOffers = [...noOffersInStars]
  }
  // console.log('SORTED OFFERS: ', sortedOffers)
  const chartfooterbanner = getImage(data.chartfooterbanner.childImageSharp)
  const salefooterbanner = getImage(data.salefooterbanner.childImageSharp)
  // console.log("CATEGORY DATA: ", categoryData)
  return (
    <Layout
      location={location}
      metaInfo={metaInfo}
      lang={lang}
      slug={slug}
      ohterLang={otherLang}
      defLang={defLang}
    >
      {/*<h1>Offer Category List Page</h1>*/}
      <SectionBannerImage
        noMargin
        title={categoryTitle}
        topBanner
        bgImage={categoryData.imageFile?.publicURL}
        alt={`${categoryTitle}`}
      />
      <SectionBox>
        <Anim>
          <SectionSeparator bgColor="linear-gradient(181deg, black 0%, lightgrey 150%)" />
        </Anim>
      </SectionBox>
      <DescriptionPart paddingBottom="200px">
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </DescriptionPart>
      <CategoryList>
        {sortedOffers.map((offer) => {
          const { title, description, value } = offer.locales.find(
            (l) => l.iso === lang
          )
          const additionalDataVAT = offer.additionalData
            .find((ad) => ad.key === "VAT")
            .locales.find((l) => l.iso === lang).value
          // console.log("Offer Image: ", offer.imageFile)
          return (
            <Anim key={offer.uid} keyProp={offer.uid}>
              <CategoryItem
                vat={additionalDataVAT}
                uid={offer.uid}
                slug={value}
                title={title}
                categoryTitle={categoryTitle}
                description={description}
                image={offer.imageFile}
                price={offer.priceFrom}
                lang={lang}
              />
            </Anim>
          )
        })}
      </CategoryList>
      <div style={{ borderTop: "10px solid #001f63", backgroundColor:"#f1f9ff" }}>
        <HelpSection>
          <h4>We are here to help</h4>
          <p>
            {localText.help[lang]}
          </p>
          <Button
            width="initial"
            bgColor="transparent"
            textColor="#776d61"
            padding="10px 35px"
            border="2px solid #ab9a87"
            margin="0"
          >
            <Link to={`${lang === 'en' ? '/contact-us' : '/sr/kontaktirajte-nas'}`}>Contact us</Link>
          </Button>
        </HelpSection>
      </div>
      {/*<SectionBannerImage>*/}
      {/*  <StaticImage src="../images/sirroco.jpeg" alt="Sirroco yacht" />*/}
      {/*</SectionBannerImage>*/}
      <SectionBannerImage noMargin image={categoryTitle === "Yachts for sale" ? salefooterbanner : chartfooterbanner} />
    </Layout>
  )
}
export const query = graphql`
  query ($category: String!) {
  salefooterbanner: file(relativePath: { eq: "salefooterbanner.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, aspectRatio: 2.22, transformOptions: {cropFocus: SOUTH})
        }
        publicURL
      }
       chartfooterbanner: file(relativePath: { eq: "chartfooterbanner.jpeg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, aspectRatio: 2.22, transformOptions: {cropFocus: SOUTH})
        }
        publicURL
      }
    allOffer(filter: { category: { uid: { eq: $category } } }) {
      nodes {
        uid
        locales {
          value
          iso
          title
          description
        }
        additionalData {
          key
          locales {
            iso
            value
          }
        }
        priceFrom
        imageFile {
          childImageSharp {
            gatsbyImageData(aspectRatio: 1)
          }
          publicURL
        }
        gallery {
          galleryImages {
            galleryImage {
              childImageSharp {
                gatsbyImageData(quality: 80)
              }
            }
          }
        }
      }
    }
  }
`
export default OfferList
